
$gray-900: #212529 !default;
$primary: $gray-900;

@import "~bootstrap/scss/bootstrap";

.btn-primary {
	&:hover {
	  background: lighten($primary, 10%);
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
	text-align: center;
}

.App-link {
	color: #61dafb;
}
::-webkit-scrollbar {
	width: 20px;
}
::-webkit-scrollbar-track {
	background-color: transparent;
}
::-webkit-scrollbar-thumb {
	background-color: #d6dee1;
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
	&:hover {
		background-color: #a8bbbf;
	}
}

.main-content {
	height: 100vh;
}