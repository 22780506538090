.logo{
    width: 200px;
    height: 54px;
}

.link-white {
    color: #fff;
}

.link-white:hover, .link-white:focus {
    color: rgb(134, 134, 134);
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important;
}